import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {LocationData} from "../models/location-data";
import {RestaurantData} from "../models/locations/restaurant-data";
import {PlatformService} from "./platform.service";

@Injectable({
    providedIn: 'root'
})
export class RestaurantsService {

    URL = '';
    httpOptions = {responseType: 'text'};

    constructor(private http: HttpClient, private platformService: PlatformService) {
        this.URL = this.platformService.getPlatFormPath("restaurants");
    }

    locationBs: BehaviorSubject<LocationData> = new BehaviorSubject(null);
    locationObs = this.locationBs.asObservable();
    filterBs: BehaviorSubject<any> = new BehaviorSubject(null);
    filterObs = this.filterBs.asObservable();

    public saveRestaurant(restaurantData: RestaurantData): Observable<RestaurantData> {
        return this.http.post(this.URL, new RestaurantData(restaurantData).toJSON())
            .pipe(
                map(res => new RestaurantData(res))
            );
    }

    public getLocation(address: string): Observable<LocationData> {
        return this.http.post(`${this.URL}/location`, {'address': address})
            .pipe(
                map(res => {
                    return new LocationData(res);
                }));
    }

    public getAddress(location: LocationData): Observable<string> {
        return this.http.post(`${this.URL}/address`, {
            'longitude': location.longitude,
            'latitude': location.latitude
        }).pipe(
            map(res => {
                return res['address'].toString();
            })
        );
    }

    public getRestaurant(id: string): Observable<RestaurantData> {
        return this.http.get(`${this.URL}/by-id/${id}`)
            .pipe(
                map(res => new RestaurantData(res))
            );
    }

    public getRestaurantByLink(link: string): Observable<any> {
        return this.http.get(`${this.URL}/details/by-link/${link}`);
    }

    public getRestaurantById(id: string): Observable<any> {
        return this.http.get(`${this.URL}/details/${id}`);
    }

    public getUsersRestaurants(id: string, offset: number): Observable<RestaurantData[]> {
        return this.http.get(`${this.URL}/user/${id}/${offset}`)
            .pipe(
                map((res: RestaurantData[]) => res.map(item => new RestaurantData(item)))
            );
    }

    public deactivate(listingId: string): Observable<boolean> {
        return this.http.post(`${this.URL}/deactivate`, {id: listingId})
            .pipe(
                map(res => !!res)
            );
    }

    public delete(listingId: string): Observable<boolean> {
        return this.http.delete(`${this.URL}/delete/${listingId}`)
            .pipe(
                map(res => !!res)
            );
    }

    public resetHighlighted(): Observable<boolean> {
        return this.http.get(`${this.URL}/reset-highlighted`)
            .pipe(
                map(res => !!res)
            );
    }

}
