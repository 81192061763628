export class LoginData {

    private _email: string;
    private _password: string;

    toJSON() {
        return {
            email: this.email,
            password: this.password
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

}
