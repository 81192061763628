import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './user/login/login.component';
import {RestaurantComponent} from './restaurant/restaurant.component';
import {MyListingsComponent} from './restaurant/lisitngs/my-listings.component';
import {ProfileComponent} from "./user/profile/profile.component";
import {LegalNoticeComponent} from "./legal/legal-notice/legal-notice.component";
import {PrivacyComponent} from "./legal/privacy/privacy.component";
import {ResetPasswordComponent} from "./user/reset-password/reset-password.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {RegisterComponent} from "./user/register/register.component";
import {ControlComponent} from "./control/control.component";
import {MembershipComponent} from "./membership/membership.component";
import {AdvantagesComponent} from "./advantages/advantages.component";

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    }, {
        path: 'liste',
        loadChildren: () => import('./restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule),
        pathMatch: 'prefix'
    },
    {
        path: 'liste/claim/:restaurantId',
        loadChildren: () => import('./restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule),
        pathMatch: 'prefix'
    },
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    {path: 'login', component: LoginComponent},
    {path: 'login/:isOwner/:claim', component: LoginComponent},
    {path: 'profil', component: ProfileComponent},
    {path: 'mitgliedschaft', component: MembershipComponent},
    {path: 'eintraege', component: MyListingsComponent},
    {path: 'uebersicht', component: DashboardComponent},
    {path: 'registrierung/:isOwner/:claim', component: RegisterComponent},
    {path: 'registrierung/:isOwner', component: RegisterComponent},
    {path: 'registrierung', component: RegisterComponent},
    {path: 'passwort-zuruecksetzen/:token', component: ResetPasswordComponent},
    {path: 'restaurant/:id', component: RestaurantComponent},
    {path: 'restaurant/:id/:active', component: RestaurantComponent},
    {path: 'impressum', component: LegalNoticeComponent},
    {path: 'vorteile', component: AdvantagesComponent},
    {path: 'datenschutz', component: PrivacyComponent},
    {path: 'steuerung', component: ControlComponent},
    {path: 'home', redirectTo: '', pathMatch: 'prefix'},
    {path: '**', redirectTo: ''},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
