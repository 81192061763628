import {Location} from "./location";

export class RestaurantData extends Location {

    _id: string;
    private _user: string;
    private _active: boolean;
    private _restaurant_name = '';
    private _order_type: string[] = [];
    private _category: string[] = [];
    private _description = '';
    private _phone = '';
    private _website = '';
    private _email = '';
    private _opentable = '';
    private _whatsapp = '';
    private _facebook = '';
    private _instagram = '';
    private _menu: any = [];
    private _images: string[] = [];
    private _link_name = "";
    private _views = 0;
    private _thumbnail: string;
    private _verified = "";
    private _hidePrices = false;

    toJSON() {
        return {
            id: this.id,
            user: this.user,
            restaurant_name: this.restaurant_name,
            active: this.active,
            order_type: this.order_type,
            category: this.category,
            street_name: this.street_name,
            street_number: this.street_number,
            code: this.code,
            city: this.city,
            description: this.description,
            phone: this.phone,
            opentable: this.opentable,
            website: this.website,
            email: this.email,
            whatsapp: this.whatsapp,
            facebook: this.facebook,
            instagram: this.instagram,
            menu: this.menu,
            images: this.images,
            link_name: this.link_name,
            views: this.views,
            verified: this.verified,
            hidePrices: this.hidePrices
        };
    }

    constructor(obj?: any) {
        super();
        Object.assign(this, obj);
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get restaurant_name(): string {
        return this._restaurant_name;
    }

    set restaurant_name(value: string) {
        this._restaurant_name = value;
    }

    get order_type(): string[] {
        return this._order_type;
    }

    set order_type(value: string[]) {
        this._order_type = value;
    }

    get category(): string[] {
        return this._category;
    }

    set category(value: string[]) {
        this._category = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get website(): string {
        return this._website;
    }

    set website(value: string) {
        this._website = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get opentable(): string {
        return this._opentable;
    }

    set opentable(value: string) {
        this._opentable = value;
    }

    get whatsapp(): string {
        return this._whatsapp;
    }

    set whatsapp(value: string) {
        this._whatsapp = value;
    }

    get facebook(): string {
        return this._facebook;
    }

    set facebook(value: string) {
        this._facebook = value;
    }

    get instagram(): string {
        return this._instagram;
    }

    set instagram(value: string) {
        this._instagram = value;
    }

    get menu(): any {
        return this._menu;
    }

    set menu(value: any) {
        this._menu = value;
    }

    get images(): string[] {
        return this._images;
    }

    set images(value: string[]) {
        this._images = value;
    }

    get user(): string {
        return this._user;
    }

    set user(value: string) {
        this._user = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get link_name(): string {
        return this._link_name;
    }

    set link_name(value: string) {
        this._link_name = value;
    }

    get views(): number {
        return this._views;
    }

    set views(value: number) {
        this._views = value;
    }

    get thumbnail(): string {
        return this._thumbnail;
    }

    set thumbnail(value: string) {
        this._thumbnail = value;
    }

    get verified(): string {
        return this._verified;
    }

    set verified(value: string) {
        this._verified = value;
    }

    get hidePrices(): boolean {
        return this._hidePrices;
    }

    set hidePrices(value: boolean) {
        this._hidePrices = value;
    }

    hasBasicInfos() {
        return !!this.restaurant_name && !!this.order_type?.length && !!this.category?.length;
    }

    hasDetails() {
        return !!this.description || !!this.phone || !!this.website || !!this.whatsapp || !!this.facebook || this.instagram;
    }
}
