import {Component, Input, OnInit} from '@angular/core';
import {RestaurantData} from "../../models/locations/restaurant-data";

@Component({
    selector: 'app-restaurant-preview',
    templateUrl: './restaurant-preview.component.html',
    styleUrls: ['./restaurant-preview.component.scss'],
})
export class RestaurantPreviewComponent implements OnInit {

    @Input() restaurant: RestaurantData = new RestaurantData();
    @Input() showActive: boolean;
    constructor() {
    }

    ngOnInit() {
    }

    public getCategories(restaurant: RestaurantData) {
        return restaurant.category.join(", ");
    }
}
