import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CookiesService {

    GA_COOKIES = ["_ga", "_gat_gtag_UA_72513385_3", "_gid"];
    HOTJAR_COOKIES = ["_hjAbsoluteSessionInProgress", "_hjFirstSeen", "_hjIncludedInPageviewSample", "_hjid"];

    constructor(private ngxCookieService: CookieService) {
    }

    deleteCookies(cookiesToDelete: string[] = []) {
        const allCookies = document.cookie.split(';');

        allCookies.forEach((cokie, index) => {
            let cookieKey = cokie.split("=")[0];

            if (!cookieKey) {
                return;
            }

            cookieKey = cookieKey.trim();

            if (!cookiesToDelete || !cookiesToDelete.length || (cookieKey && cookiesToDelete.includes(cookieKey))) {
                this.ngxCookieService.delete(cookieKey, "/", ".foodla.eu", false);
                this.ngxCookieService.delete(cookieKey, "/", ".foodla.de", false);
                this.ngxCookieService.delete(cookieKey, "/", "localhost", false);
                this.ngxCookieService.deleteAll("/", ".ionicframework.com", false);
            }

        });
    }

    public cleanUpCookies() {
        this.ngxCookieService.deleteAll();
        console.log("Prod" + environment.production);

        if (!this.isStats() && !this.isMarketing()) {
            this.deleteCookies();
            localStorage.removeItem("_hjid");
            return;
        }

        if (!this.isStats() || (environment && !environment.production)) {
            this.deleteCookies(this.GA_COOKIES);
        }

        if (!this.isMarketing() || (environment && !environment.production)) {
            this.deleteCookies(this.HOTJAR_COOKIES);
            localStorage.removeItem("_hjid");
        }
    }

    isStats() {
        const settings = localStorage.getItem('cookies');
        const settingsNum = Number(settings);
        return settings && settingsNum && (settingsNum === 2 || settingsNum === 6);
    }

    isMarketing() {
        const settings = localStorage.getItem('cookies');
        const settingsNum = Number(settings);
        return settings && settingsNum && (settingsNum === 4 || settingsNum === 6);
    }
}
