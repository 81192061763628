import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";
import {LocalStorageService} from "../services/local-storage.service";
import {Router} from "@angular/router";
import {CookiesService} from "../services/cookies.service";
import {Components} from "@ionic/core";
import IonToggle = Components.IonToggle;
import {Meta} from "@angular/platform-browser";

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements AfterViewInit {

    @ViewChild("ionToggleMarketing", {static: false}) ionToggleMarketing: ElementRef;
    @ViewChild("ionToggleStats", {static: false}) ionToggleStats: ElementRef;

    stats = false;
    marketing = false;
    details: boolean;
    showStatistics: boolean;
    showMarketing: boolean;

    constructor(private popover: PopoverController,
                public router: Router,
                public navParams: NavParams,
                private localStorageService: LocalStorageService,
                private changeDetection: ChangeDetectorRef,
                public cookiesService: CookiesService) {
    }

    ngAfterViewInit() {
        this.details = !!this.navParams?.data?.details;
        this.stats = this.cookiesService.isStats();
        this.marketing = this.cookiesService.isMarketing();
        this.ionToggleStats.nativeElement.checked = this.stats;
        this.ionToggleMarketing.nativeElement.checked = this.marketing;
        this.changeDetection.detectChanges();
    }

    acceptAll() {
        this.localStorageService.setItem('cookies', 6);
        this.popover.dismiss();
    }

    save() {
        let value = 0;

        if (this.stats) {
            value = value + 2;
        }

        if (this.marketing) {
            value = value + 4;
        }

        this.localStorageService.setItem('cookies', value);
        setTimeout(() => this.cookiesService.cleanUpCookies(), 1000);
        this.popover.dismiss();
    }

    navigate(navigationLink: string) {
        this.popover.dismiss();
        this.router.navigate([navigationLink]);
    }

    openDetails() {
        this.popover.dismiss({data: "details"}).then();
    }

    goBack() {
        this.popover.dismiss({data: "back"}).then();
    }

    setStats(value: boolean) {
        this.stats = !this.stats;
    }

    setMarketing(value: boolean) {
        this.marketing = !this.marketing;
    }

    toggleStatistics() {
        this.showStatistics = !this.showStatistics;
    }

    toggleMarketing() {
        this.showMarketing = !this.showMarketing;
    }

}
