import {Component, OnInit} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-listing-options',
    templateUrl: './listing-options.component.html',
    styleUrls: ['./listing-options.component.scss'],
})
export class ListingOptionsComponent implements OnInit {
    active: boolean;

    constructor(private popover: PopoverController, public navParams: NavParams) {
        this.active = this.navParams.data.active;
        console.log(this.active);
    }

    ngOnInit() {
    }

    onDeactivate() {
        this.popover.dismiss({data: "deactivate"});
    }

    onDelete() {
        this.popover.dismiss({data: "delete"});
    }

    onActivate() {
        this.popover.dismiss({data: "activate"});
    }
}
