export class ResetPasswordData {

    private _id: string;
    private _newPassword: string;
    private _password: string;

    toJSON() {
        return {
            id: this.id,
            newPassword: this.newPassword,
            password: this.password
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get newPassword(): string {
        return this._newPassword;
    }

    set newPassword(value: string) {
        this._newPassword = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }
}
