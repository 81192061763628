import {Location} from "./location";

export class ShippingData extends Location {

    private _id = '';
    private _order_id = '';
    private _email = '';
    private _first_name = '';
    private _last_name = '';
    private _payment_method = '';

    constructor(obj?: any) {
        super();
        Object.assign(this, obj);
    }

    toJSON() {
        return {
            id: this.id,
            order_id: this.order_id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            payment_method: this.payment_method,
            street_name: this.street_name,
            code: this.code,
            city: this.city,
            street_number: this.street_number,
        };
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get order_id(): string {
        return this._order_id;
    }

    set order_id(value: string) {
        this._order_id = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get first_name(): string {
        return this._first_name;
    }

    set first_name(value: string) {
        this._first_name = value;
    }

    get last_name(): string {
        return this._last_name;
    }

    set last_name(value: string) {
        this._last_name = value;
    }

    get payment_method(): string {
        return this._payment_method;
    }

    set payment_method(value: string) {
        this._payment_method = value;
    }
}
