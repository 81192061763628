import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {RegisterData} from '../models/register-data';
import {LoginData} from '../models/login-data';
import {LocalStorageService} from './local-storage.service';
import {map} from "rxjs/operators";
import {ResetPasswordData} from "../models/reset-password-data";
import {ClaimRestaurantData} from "../models/claim-restaurant-data";
import {PlatformService} from "./platform.service";

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    URL = '';
    httpOptions = {responseType: 'text'};
    private subject = new Subject<any>();

    constructor(private http: HttpClient,
                private platformService: PlatformService,
                private localStorageService: LocalStorageService) {
        this.URL = this.platformService.getPlatFormPath("users");
    }

    onUserChanged(): Observable<any> {
        return this.subject.asObservable();
    }

    sendUser(user: any) {
        this.subject.next(user);
    }

    public registerUser(registerData: RegisterData): Observable<any> {
        return this.http.post(this.URL, registerData.toJSON());
    }

    public updateUser(registerData: RegisterData, id: string): Observable<any> {
        return this.http.post(`${this.URL}/update`, {'user': registerData.toJSON(), 'id': id});
    }

    public loginUser(loginData: LoginData): Observable<any> {
        return this.http.post(this.URL + '/login', loginData.toJSON());
    }

    public resetPassword(resetPasswordData: ResetPasswordData): Observable<any> {
        return this.http.post(this.URL + '/pw', resetPasswordData.toJSON());
    }

    public resetPasswordByMail(resetPasswordData: ResetPasswordData): Observable<any> {
        return this.http.post(this.URL + '/pw/from-mail', resetPasswordData.toJSON());
    }

    public claimRestaurant(claimRestaurantData: ClaimRestaurantData): Observable<any> {
        return this.http.post(this.URL + '/claim', claimRestaurantData.toJSON());
    }

    public resetMail(email: string): Observable<any> {
        return this.http.post(this.URL + '/pw/mail', {'email': email});
    }

    public findUser(id: string): Observable<RegisterData> {
        return this.http.get(`${this.URL}/${id}`)
            .pipe(
                map(res => new RegisterData(res))
            );
    }


    public deleteUser(): Observable<any> {
        return this.http.delete(`${this.URL}/delete`).pipe(
            map(res => !!res)
        );
    }

    public getUser(): any {
        return this.localStorageService.getItem('user');
    }

    public getToken(): any {
        return this.localStorageService.getItem('user')?.token;
    }

    public setUser(data: any) {
        return this.localStorageService.setItem('user', data);
    }

    public removeUser() {
        this.sendUser(null);
        return this.localStorageService.removeIem('user');
    }

}
