import {Component, OnInit} from '@angular/core';
import {ToastService} from "../services/toast.service";
import {RestaurantsService} from "../services/restaurants.service";

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.scss'],
})
export class ControlComponent implements OnInit {

    constructor(private toastService: ToastService,
                private restaurantsService: RestaurantsService) {
    }

    ngOnInit() {
    }

    resetHighlighted() {
        this.restaurantsService.resetHighlighted().subscribe(async res => {
            await this.toastService.infoToast("Hervorgehobene Restaurants zurückgesetzt");
        }, async (error) => {
            await this.toastService.errorToast();
        });
    }
}
