export class ClaimRestaurantData {

    private _restaurant_id: string;
    private _name: string;
    private _email: string;

    toJSON() {
        return {
            restaurant_id: this.restaurant_id,
            name: this.name,
            email: this.email
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get restaurant_id(): string {
        return this._restaurant_id;
    }

    set restaurant_id(value: string) {
        this._restaurant_id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }
}
