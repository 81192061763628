import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RestaurantListComponent} from "../restaurant/restaurant-list/restaurant-list.component";
import {RestaurantPreviewComponent} from "../restaurant/restaurant-preview/restaurant-preview.component";
import {IonicModule} from "@ionic/angular";
import {MenuComponent} from "../menu/menu.component";
import {PrivacyComponent} from "../legal/privacy/privacy.component";
import {FormsModule} from '@angular/forms';
import {StepperComponent} from "./stepper/stepper.component";
import {OpentableButtonComponent} from "../opentable/opentable-button/opentable-button.component";
import {AddDishComponent} from "../restaurant/add-dish/add-dish.component";
import {AddDishPopoverComponent} from "../restaurant/add-dish-popover/add-dish-popover.component";

import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
    declarations: [
        RestaurantListComponent,
        RestaurantPreviewComponent,
        MenuComponent,
        PrivacyComponent,
        StepperComponent,
        OpentableButtonComponent,
        AddDishComponent, AddDishPopoverComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        IonicModule,
        NgSelectModule
    ],
    exports: [
        RestaurantListComponent,
        RestaurantPreviewComponent,
        FormsModule,
        MenuComponent,
        StepperComponent,
        OpentableButtonComponent,
        AddDishComponent, AddDishPopoverComponent,
        NgSelectModule
    ]
})
export class SharedModule {
}
