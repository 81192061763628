import { Component, OnInit } from '@angular/core';
import {CookiesComponent} from "../../cookies/cookies.component";
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  async presentCookiePopover() {
    const popover = await this.popoverController.create({
      component: CookiesComponent,
      componentProps: {details: true},
      cssClass: 'popover-small'
    });
    await popover.present();
  }
}
