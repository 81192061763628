import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/core';
import {ActionSheetController, AlertController, LoadingController, Platform} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {DishData} from '../models/dish-data';
import _ from 'lodash';
import {RestaurantData} from '../models/locations/restaurant-data';
import {RestaurantsService} from '../services/restaurants.service';
import {UsersService} from '../services/users.service';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "../services/toast.service";
import {OrderTypeEnum} from "../enums/order-type-enum";
import {RestaurantImageData} from "../models/restaurant-image-data";
import {ImagesService} from "../services/images.service";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {Location} from "../models/locations/location";
import {MembershipService} from "../services/membership.service";

@Component({
    selector: 'app-restaurant',
    templateUrl: './restaurant.component.html',
    styleUrls: ['./restaurant.component.scss'],
})
export class RestaurantComponent implements OnInit {
    @ViewChild('fileInputPdf', {static: false}) fileInputPdf: ElementRef;
    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

    public orderTypes = OrderTypeEnum;

    public restaurantData: RestaurantData = new RestaurantData();
    public marker: any;
    public user: any;
    public fileIsOver = false;
    public dishCategories: any[] = [];
    public newDish: DishData;
    public dishes: DishData[] = [];
    public error: any;
    public timeout = 0;
    public errors: string[] = [];
    public triedActivate: boolean;
    public editProfilePicMode: boolean;
    public images: string[];
    public loaded: boolean;
    public missingSteps = [];
    public maxSteps = 5;
    public hideHints: boolean;
    public options: any;
    public hasPremium = false;

    loadingPopover = null;

    constructor(private loadingController: LoadingController,
                private plt: Platform,
                public router: Router,
                private restaurantsService: RestaurantsService,
                private imagesService: ImagesService,
                private actionSheetCtrl: ActionSheetController,
                private _sanitizer: DomSanitizer,
                private usersService: UsersService,
                private route: ActivatedRoute,
                public toastService: ToastService,
                private membershipService: MembershipService,
                private changeDetection: ChangeDetectorRef,
                public alertController: AlertController) {
        this.loaded = false;
        this.route.params.subscribe(async params => {
            this.loaded = false;

            this.triedActivate = false;

            if (params.id === "new") {
                this.restaurantData = new RestaurantData();
                this.loaded = true;
                return;
            }

            this.restaurantsService.getRestaurant(params.id).subscribe(res => {

                this.restaurantData = res;
                this.imagesService.getImagesByRestaurant(this.restaurantData.link_name).subscribe(imgRes => {
                    this.images = imgRes?.images || [];
                    this.checkProgress();
                });

                this.dishCategories = this.restaurantData.menu.map(item => item.category);

                this.restaurantData.menu.forEach(item => {
                    this.dishes = this.dishes.concat(item.dishes);
                    this.addCustomCategory(item.category);
                });

                this.loaded = true;
            }, () => this.loaded = true);

            if (params.active) {
                await this.activateRestaurant();
            }
        });
    }

    ngOnInit() {
        this.newDish = new DishData();
        this.user = this.usersService.getUser();
        this.checkSubscription();
    }

    private checkSubscription() {
        this.membershipService.getActive().subscribe((result) => {
            this.hasPremium = result;
        });
    }

    initMarker() {
        this.marker = {
            position: {
                lat: ((Math.random() - 0.5) * 2) / 10,
                lng: ((Math.random() - 0.5) * 2) / 10,
            },
            label: {
                color: 'red',
                text: 'Marker label ',
            },
            title: 'Marker title ',
            options: {animation: google.maps.Animation.BOUNCE},
        };
    }

    async selectImageSource() {
        const buttons = [
            {
                text: 'Foto aufnehmen',
                icon: 'camera',
                handler: () => {
                    this.addImage(CameraSource.Camera);
                }
            },
            {
                text: 'Aus Galerie auswählen',
                icon: 'image',
                handler: () => {
                    this.addImage(CameraSource.Photos);
                }
            }
        ];

        // Only allow file selection inside a browser
        if (!this.plt.is('hybrid')) {
            buttons.push({
                text: 'Aus Dateien auswählen',
                icon: 'attach',
                handler: () => {
                    this.openFileSelector();
                }
            });
        }

        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Wähle ein Bild',
            buttons
        });
        await actionSheet.present();
    }

    openFileSelector() {
        this.fileInput.nativeElement.click();
    }

    async addImage(source: CameraSource) {
        await this.presentLoadingPopover();

        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Base64,
            source
        });
        const dataURL = `data:image/jpeg;base64,${image.base64String}`;

        await this.uploadImage(dataURL.split(',')[1]);
        await this.dismissLoadingPopover();
        this.reloadImages();
    }

    private reloadImages() {
        this.imagesService.getImagesByRestaurant(this.restaurantData.link_name).subscribe(async imgRes => {
            this.images = imgRes?.images || this.images;
            this.changeDetection.detectChanges();
        });
    }

    async addFile() {
        await this.presentLoadingPopover();

        const files = Array.from(this.fileInput.nativeElement.files);
        files.forEach(async (file: File) => {
            await this.handleFile(file);
        });

        await this.dismissLoadingPopover();
        this.reloadImages();
    }

    private async uploadImage(base64: string) {

        const imageData = new RestaurantImageData({id: this.restaurantData.id, images: Array.of(base64)});

        this.imagesService.uploadImages(imageData).subscribe(async res => {

            if (!res?.id) {
                await this.toastService.errorToast();
                return;
            }

            this.restaurantData.images.push(res.id);

            this.changeDetection.detectChanges();
            await this.toastService.infoToast();
            this.checkProgress();
        }, async (error) => {
            await this.toastService.errorToast(error.error);
        });
    }

    private async presentLoadingPopover() {
        if (!this.loadingPopover) {
            this.loadingPopover = await this.loadingController.create({
                message: 'Bild wird hochgeladen'
            });
            await this.loadingPopover.present();
        }
    }

    private async dismissLoadingPopover() {

        if (!this.loadingPopover) {
            return;
        }

        await this.loadingPopover.dismiss();
        this.loadingPopover = null;
    }

    deleteImage(image, index) {
        const imageId = this.restaurantData.images[index];
        this.imagesService.delete(this.restaurantData.id, imageId).subscribe(async res => {

            if (!res) {
                await this.toastService.errorToast();
                return;
            }

            this.restaurantData.images.splice(index, 1);

            this.imagesService.getImagesByRestaurant(this.restaurantData.link_name).subscribe(imgRes => {
                this.images = imgRes?.images || [];
                this.checkProgress();
                this.changeDetection.detectChanges();
            });
            await this.toastService.infoToast();
        }, async () => {
            await this.toastService.errorToast();
        });
    }

    async dropped(entries: NgxFileDropEntry[]) {
        await this.presentLoadingPopover();

        for (const droppedFile of entries) {

            if (!droppedFile.fileEntry.isFile) {
                continue;
            }

            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file(async (file: File) => {
                await this.handleFile(file);
            });
        }

        this.fileIsOver = false;
        await this.dismissLoadingPopover();
        this.reloadImages();
    }

    private async handleFile(file: File) {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (event) => {
            if (typeof event.target.result === "string") {
                await this.uploadImage(event.target.result.split(',')[1]);
            }
        };
    }

    fileOver() {
        this.fileIsOver = true;
    }

    fileLeave() {
        this.fileIsOver = false;
    }

    addCustomCategory = (term) => ({id: term, name: term});

    addDish(newDish: DishData) {

        newDish = newDish || this.newDish;

        if (!newDish?.title || !newDish.description) {
            return;
        }

        newDish.title = newDish.title?.trim();
        this.dishes = this.dishes.filter(d => d.title?.trim() !== newDish.title);

        this.dishes.push(newDish);
        this.newDish = new DishData();
        this.setMenuFromDishes();
    }

    private setMenuFromDishes() {
        this.restaurantData.menu = _(this.dishes)
            .groupBy((dish: DishData) => dish.category)
            .map((value, key) => ({category: key, dishes: value}))
            .value();
        this.changeRestaurantData();
        this.dishCategories = this.restaurantData.menu.map(item => item.category);
    }

    removeDish(item: string) {
        this.dishes = this.dishes.filter(d => d.title?.trim() !== item);
        this.setMenuFromDishes();
        this.dishCategories = this.restaurantData.menu.map(i => i.category);
    }

    async activateRestaurant() {
        this.triedActivate = true;

        if (this.restaurantData.hasBasicInfos() && this.restaurantData.hasAddress()) {
            this.restaurantData.active = true;
            this.changeRestaurantData();
            return;
        }

        await this.toastService.errorToast("Standort und Basisinformationen sind verpflichtend");
    }

    changeCategories(categories: string[]) {
        // bestehende Kategorien sollten behalten werden
        categories = categories.filter(category => !this.restaurantData.category?.includes(category));
        this.restaurantData.category = [...this.restaurantData.category, ...categories];
        this.changeRestaurantData();
    }

    changeRestaurantData(skipTimeout?: boolean) {

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (!this.restaurantData.restaurant_name) {
            return;
        }

        // @ts-ignore
        this.timeout = setTimeout(() => {
            this.restaurantData.user = this.usersService.getUser().id;
            this.restaurantsService.saveRestaurant(this.restaurantData).subscribe(async res => {

                if (res && res.id) {
                    this.restaurantData = res;
                    (await this.toastService.infoToast());
                } else {
                    (await this.toastService.errorToast());
                }

                this.checkProgress();
            }, async () => {
                await this.toastService.errorToast();
            });
        }, skipTimeout ? 0 : 2000);
    }

    changeAddress(restaurantData: Location) {
        this.restaurantData.street_name = restaurantData.street_name;
        this.restaurantData.street_number = restaurantData.street_number;
        this.restaurantData.city = restaurantData.city;
        this.restaurantData.code = restaurantData.code;
        this.restaurantData = new RestaurantData(this.restaurantData);
        this.changeRestaurantData();
    }

    async presentImageAlert(img, i) {
        const alert = await this.alertController.create({
            cssClass: '',
            header: 'Löschen?',
            message: 'Möchtest du das Bild wirklich löschen?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        return;
                    }
                }, {
                    text: 'Ja, Löschen',
                    handler: () => {
                        this.deleteImage(img, i);
                    }
                }
            ]
        });
        await alert.present();
    }

    private checkProgress() {
        this.missingSteps = [];

        if (!this.restaurantData.hasBasicInfos()) {
            this.missingSteps.push({text: "Standardinformationen sind verpflichtend", id: "basic"});
            return;
        }

        if (!this.restaurantData.hasAddress()) {
            this.missingSteps.push({text: "Füge eine Adresse hinzu", id: "address"});
            return;
        }

        if (!this.restaurantData.hasDetails()) {
            this.missingSteps.push({text: "Füge Details hinzu", id: "details"});
        }

        if (!this.dishes?.length) {
            this.missingSteps.push({text: "Füge eine Speisekarte hinzu", id: "menu"});
        }

        if (!this.restaurantData.images?.length) {
            this.missingSteps.push({text: "Lade ein Profilbild hoch", id: "images"});
        } else if (this.restaurantData.images.length < 4) {
            this.missingSteps.push({text: "Lade 4 Bilder hoch", id: "images"});
        }
    }

    async scroll(id: string) {
        const el = document.getElementById(id);
        el.scrollIntoView();
    }

    closeHints() {
        this.hideHints = true;
    }

    toggleChangeProfilePic() {
        this.editProfilePicMode = !this.editProfilePicMode;
    }

    chooseProfileImage(img: number) {
        const imageId = this.restaurantData.images[img];

        this.imagesService.chooseProfilePicture(this.restaurantData._id, imageId).subscribe(async imgRes => {
            this.images = imgRes?.images || [];
            this.restaurantData.images = this.restaurantData.images.filter(i => i !== imageId);
            this.restaurantData.images.unshift(imageId);
            this.editProfilePicMode = false;
            await this.toastService.infoToast();
            this.checkProgress();
        });
    }

}
