import {Component, OnInit, ViewChild} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";
import {DishData} from "../../models/dish-data";

@Component({
    selector: 'app-add-dish-popover',
    templateUrl: './add-dish-popover.component.html',
    styleUrls: ['./add-dish-popover.component.scss'],
})
export class AddDishPopoverComponent implements OnInit {

    @ViewChild('addDishComponent') addDishComponent;

    dishCategories = [];
    dish = new DishData();
    skipPrice: boolean;
    private deleteItem: string;

    constructor(public navParams: NavParams,
                private popover: PopoverController) {

        this.dishCategories = this.navParams?.data?.dishCategories;
        Object.assign(this.dish, this.navParams?.data?.dish || {});
        this.skipPrice = this.navParams?.data?.skipPrice;
    }

    async addDish(dish?: DishData) {
        await this.popover.dismiss({dish, deleteItem: this.deleteItem});
    }

    ngOnInit(): void {
    }

    async removeDish(item: string) {
        this.deleteItem = item;
    }
}
