import {Component, OnInit, ViewChild} from '@angular/core';
import {MembershipService} from "../services/membership.service";
import {UsersService} from "../services/users.service";
import {StepperComponent} from "../shared/stepper/stepper.component";
import {ToastService} from "../services/toast.service";
import {FormBuilder} from "@angular/forms";
import {RestaurantsService} from "../services/restaurants.service";
import {RestaurantData} from "../models/locations/restaurant-data";
import {SubscriptionProductData} from "../models/subscription-product-data";
import {PaymentProviderData} from "../models/payment-provider-data";
import {ShippingData} from "../models/locations/shipping-data";
import {IPayPalConfig, IPayPalUrlConfig, NgxPaypalComponent, PayPalScriptService} from "ngx-paypal";
import {Location} from "../models/locations/location";
import {AlertController} from "@ionic/angular";

@Component({
    selector: 'app-premium',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent implements OnInit {

    @ViewChild("paypalComponent", {static: false}) paypalComponent: NgxPaypalComponent;
    @ViewChild("stepper", {static: false}) stepper: StepperComponent;

    private showPaymentDescription: string;
    public payPalConfig ?: IPayPalConfig;
    public userRestaurants: RestaurantData[];
    public shippingData: ShippingData = new ShippingData();
    public selectedSubscription = new SubscriptionProductData();
    public activeSubscription = new SubscriptionProductData();
    public csvAddress: string;

    constructor(private toastService: ToastService,
                private userService: UsersService,
                private formBuilder: FormBuilder,
                private restaurantsService: RestaurantsService,
                private membershipService: MembershipService,
                private payPalScriptService: PayPalScriptService,
                public alertController: AlertController) {
    }


    public standardConfig ?: IPayPalUrlConfig = {
        clientId: 'AdE7uH1ncpoB_kfOo2r3X8afQULTqre1rACWDVO2nVmd0PXm_7jeagEkie9fr6ofdwIUiqR7FB3TRzdY',
        currency: "EUR",
        vault: "true",
    };

    public error: string;

    public steps: string[] = ["Abonnement wählen", "Rechnungsdetails hinzufügen", "Zahlungsmethode wählen"];

    public paymentMethods: PaymentProviderData[] = [new PaymentProviderData({
        id: "paypal",
        title: "PayPal",
        description: "Mit Paypal bezahlen. Solltest du keinen Paypal-Account besitzen, kannst du auch mit deiner Kreditkarte bezahlen."
    })];

    public freeFeatures = [
        "Speisekarte präsentieren",
        "erhöhte Sichtbarkeit",
        "neue Kunden",
        "keine Bewertungsplattform",
        "Management Zentrale",
        "Kontaktinformationen",
    ];

    public freeOnlyFeatures = [
        "4 Bilder Upload"
    ];

    public premiumFeatures = [
        "10 Bilder Upload",
        "Vorschaubild auswählen",
        "Opentable Anbindung",
        "24h Support"
    ];

    public products: SubscriptionProductData[] = [
        new SubscriptionProductData({
            id: 0,
            price: 0.00,
            name: "Foodla Free",
            features: [...this.freeFeatures, [...this.freeOnlyFeatures]]
        }),
        new SubscriptionProductData({
            id: 1,
            price: 9.99,
            name: "Foodla Premium",
            features: [...this.freeFeatures, ...this.premiumFeatures]
        })
    ];

    ngOnInit() {
        this.initSubscriptions();
        this.initBillingDetails();
        this.payPalConfig = this.getConfig();
    }

    async checkNextStep(id?: number) {
        this.error = "";
        const isSelectSubscription = this.stepper.activeStep === 0;

        if (isSelectSubscription) {
            this.selectedSubscription = this.products.find(product => product.id === id);
        }

        const sameSelected = (this.activeSubscription.id || 0) === (this.selectedSubscription.id || 0);
        const freeSelected = !this.selectedSubscription.id;

        if (isSelectSubscription && sameSelected) {
            await this.toastService.infoToast();
            return;
        }

        if (isSelectSubscription && freeSelected) {
            await this.presentAlertConfirm();
            return;
        }

        if (this.stepper.activeStep === 1) {
            const hasAddress = this.validateBillingDetails();
            this.stepper.nextStep(!hasAddress);
            this.payPalScriptService.registerPayPalScript(this.standardConfig, (payPalApi) => {
                this.paypalComponent.customInit(payPalApi);
            });
            return;
        }

        this.stepper.nextStep();
    }

    private validateBillingDetails() {

        const hasAddress = this.shippingData && !!this.shippingData.street_name
            && !!this.shippingData.city
            && !!Number(this.shippingData.code)
            && !!Number(this.shippingData.street_number)
            && !!this.shippingData.first_name
            && !!this.shippingData.last_name
            && !!this.shippingData.email;

        if (!hasAddress) {
            this.error = "Bitte fülle alle Felder aus";
        }

        return hasAddress;
    }

    private initBillingDetails() {
        const user = this.userService.getUser();

        this.userService.findUser(user.id).subscribe(userData => {
            this.shippingData.email = userData.email;
            this.shippingData.first_name = userData.first_name;
            this.shippingData.last_name = userData.last_name;
        });

        this.restaurantsService.getUsersRestaurants(user.id, 0).subscribe((restaurants: RestaurantData[]) => {
            // TODO remove slice when admin functionality is removed
            this.userRestaurants = restaurants.map(listing => new RestaurantData(listing));
        });
    }

    checkSubscription(actions) {

        if (!actions?.order) {
            return;
        }

        actions.order.get().then(details => {
            this.saveSubscription(details.id);
        });

    }

    private saveSubscription(id) {
        this.shippingData.payment_method = "PayPal";
        this.shippingData.order_id = id;
        this.membershipService.saveSubscription(this.shippingData).subscribe(async res => {
            this.activeSubscription = this.products[1];
            // await this.checkNextStep();
            window.location.reload();
            await this.toastService.successToast("Bestellung erfolgreich abgeschlossen");
        }, async () => {
            await this.toastService.errorToast();
        });
    }

    private initSubscriptions() {
        this.membershipService.getActive().subscribe((result) => {
            this.activeSubscription = !result ? this.products[0] : this.products[1];
            this.selectedSubscription = this.activeSubscription;
        });
    }

    changeRestaurantAddress(csvAddress: string) {
        const values = csvAddress.split(",");

        const restaurantData = this.userRestaurants.find(listing =>
            listing.street_name === values[0] &&
            String(listing.street_number) === values[1] &&
            Number(listing.code) === Number(values[2]) &&
            listing.city === values[3]);
        this.shippingData.setLocation(restaurantData);
        this.shippingData = Object.assign(new ShippingData(), this.shippingData);
    }

    changeAddress(location: Location) {
        this.shippingData.setLocation(location);
        this.shippingData = Object.assign(new ShippingData(), this.shippingData);
    }

    showMore(id: string) {
        this.showPaymentDescription = id;
    }

    getConfig(): IPayPalConfig {
        return {
            clientId: 'AdE7uH1ncpoB_kfOo2r3X8afQULTqre1rACWDVO2nVmd0PXm_7jeagEkie9fr6ofdwIUiqR7FB3TRzdY',
            currency: "EUR",
            vault: "true",
            advanced: {
                commit: 'true'
            },
            style: {
                shape: "pill",
                label: 'paypal',
                layout: 'horizontal'
            },
            onInit: (data, actions) => {
                // this.paypalComponent.config;
            },
            createSubscription: (data, actions) => {
                return actions.subscription.create({
                    quantity: 1,
                    plan_id: "P-2AK18276TS7948513MFDNQHI"
                });
            },
            onApprove: (data, actions) => {
                this.shippingData.id = data.subscriptionID;
                this.checkSubscription(actions);
            },
            onCancel: (data, actions) => {
                this.checkSubscription(actions);
            },
            onError: async () => {
                await this.toastService.errorToast("Bei der Bestellung ist ein Fehler aufgetreten.");
            }
        };
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            cssClass: '',
            header: 'Abonnement beenden?',
            message: 'Möchten du dein Abonnement <strong>wirklich</strong> beenden?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        return;
                    }
                }, {
                    text: 'Ja, beenden',
                    handler: () => {
                        this.cancelSubscription();
                    }
                }
            ]
        });

        await alert.present();
    }

    private cancelSubscription() {
        this.membershipService.cancel().subscribe(async res => {
            await this.toastService.infoToast('Abonnement würde gekündigt.');
            window.location.reload();
        }, async () => {
            await this.toastService.errorToast('Abonnement konnte nicht gekündigt werden.');
        });
    }
}
