import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {DishData} from "../../models/dish-data";

@Component({
    selector: 'app-add-dish',
    templateUrl: './add-dish.component.html',
    styleUrls: ['./add-dish.component.scss'],
})
export class AddDishComponent implements OnInit {
    originalTitle = "";
    _dish: DishData = new DishData();
    get dish() {
        return new DishData(this._dish);
    }

    @Input() set dish(data: DishData) {
        this.originalTitle = data?.title;
        this._dish = new DishData(data);
    }

    @Input() edit = false;
    @Input() dishCategories = [];
    @Input() selected = "";
    @Input() skipPrice = false;
    @ViewChild('ngSelect') ngSelect: NgSelectComponent;

    @Output() onAddDish: EventEmitter<DishData> = new EventEmitter<DishData>();
    @Output() onRemoveDish: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {

    }

    addCategory(item) {
        this.dish.category = item?.name || item || "";
        this._dish.category = item?.name || item || "";
    }

    addCustomCategory = (term) => ({id: term, name: term});

    clearCategories() {
        this._dish.category = '';
    }

    addDish() {
        if (this._dish.title !== this.originalTitle) {
            this.onRemoveDish.emit(this.originalTitle);
        }
        this.onAddDish.emit(this._dish);
        this._dish = new DishData();
        this.ngSelect.handleClearClick();
    }

    removeDish() {
        this.onRemoveDish.emit(this.originalTitle);
    }
}
