import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    @Output() onNavigate: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    close(navigation: string) {
        this.onNavigate.emit(navigation);
    }
}
