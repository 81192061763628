import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {Location} from "../../models/locations/location";

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {

    @Input() set location(location: Location) {
        const restaurantChanged = this.getRestaurantChanged(location);

        if (restaurantChanged) {
            this.addressData = new Location(location);
            this.addressRestaurantDataOriginal = new Location(location);
        }
    }

    constructor() {
    }

    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    @Input() streetActive = false;
    @Output() changeValue: EventEmitter<Location> = new EventEmitter<Location>();

    public options: any;
    public addressData: Location = new Location();
    public addressRestaurantDataOriginal: Location = new Location();

    private getRestaurantChanged(location: Location) {
        return location.street_name !== this.addressData.street_name
            || location.street_number !== this.addressData.street_number
            || location.city !== this.addressData.city
            || location.code !== this.addressData.code;
    }

    ngOnInit() {
        this.options = {
            types: ['address'],
            componentRestrictions: {country: 'de'},
            fields: ["address_components"]
        };
    }

    changeValues(event: Address) {

        if (!this.addressData) {
            return;
        }

        const addressRestaurantData = new Location();
        addressRestaurantData.street_name = event?.address_components.find(c => c.types.includes("route"))?.long_name;
        addressRestaurantData.city = event?.address_components.find(c => c.types.includes("locality"))?.long_name;
        const newCode = Number(event?.address_components.find(c => c.types.includes("postal_code"))?.long_name);
        addressRestaurantData.code = !!newCode ? newCode : this.addressData.code;
        const newStreetNumber = event?.address_components.find(c => c.types.includes("street_number"))?.long_name;
        addressRestaurantData.street_number = !!newStreetNumber ? newStreetNumber :  this.addressData.street_number;

        if (this.getRestaurantChanged(addressRestaurantData)) {
            this.changeValue.emit(addressRestaurantData);
        }
    }

    changeSingleValue() {

        if (this.getRestaurantChanged(this.addressRestaurantDataOriginal)) {
            this.changeValue.emit(this.addressData);
            this.addressRestaurantDataOriginal = new Location(this.addressData);
        }
    }
}
