import {ChangeDetectorRef, Component} from '@angular/core';
import {AlertController, MenuController, Platform, PopoverController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import {UsersService} from './services/users.service';
import {UserData} from './models/user-data';
import {CookiesComponent} from "./cookies/cookies.component";
import {ToastService} from "./services/toast.service";
import {NgxHotjarService} from "ngx-hotjar";
import {CookiesService} from "./services/cookies.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    user: UserData;

    constructor(public platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                private menuController: MenuController,
                public router: Router,
                private userService: UsersService,
                private popoverController: PopoverController,
                public alertController: AlertController,
                public toastService: ToastService,
                private changeDetection: ChangeDetectorRef,
                private hjService: NgxHotjarService,
                private cookiesService: CookiesService) {
        this.initializeApp().then();
        this.userService.onUserChanged().subscribe(user => {
            this.user = user;
        });
    }

    async initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.user = this.userService.getUser();
            this.cleanUpCookies(1000);
        });
        const cookieSetting = localStorage.getItem("cookies");

        if (!cookieSetting) {
            await this.presentCookiePopover();
        }
    }

    public cleanUpCookies(timeout?: number) {
        setTimeout(() => this.cookiesService.cleanUpCookies(), timeout);
    }

    async presentCookiePopover(details?: boolean) {
        const popover = await this.popoverController.create({
            component: CookiesComponent,
            componentProps: {details},
            cssClass: 'popover-small'
        });
        await popover.present();

        return popover.onDidDismiss().then((data: any) => {

            if (data?.data?.data === "details") {
                this.presentCookiePopover(true);
            }

            if (data?.data?.data === "back") {
                this.presentCookiePopover();
            }
        });
    }

    openMenu() {
        this.menuController.open();
    }

    close(navigationLink?: string) {

        if (navigationLink) {
            this.router.navigate([navigationLink]);
        }

        this.menuController.close();
    }

    async presentLogoutAlert() {
        const alert = await this.alertController.create({
            cssClass: '',
            header: 'Ausloggen?',
            message: 'Möchtest du dich wirklich ausloggen?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        return;
                    }
                }, {
                    text: 'Ja, ausloggen',
                    handler: async () => {
                        await this.toastService.infoToast('Du wurdest erfolgreich ausgeloggt');
                        await this.router.navigate(['/']);
                        this.userService.removeUser();
                        this.user = null;
                        this.changeDetection.detectChanges();
                    }
                }
            ]
        });
        await alert.present();
    }

}
