import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RestaurantsService} from '../../services/restaurants.service';
import {UsersService} from "../../services/users.service";
import {RestaurantData} from "../../models/locations/restaurant-data";
import {PopoverController} from "@ionic/angular";
import {ListingOptionsComponent} from "../listing-options/listing-options.component";
import {ToastService} from "../../services/toast.service";
import {ImagesService} from "../../services/images.service";

@Component({
    selector: 'app-lisitngs',
    templateUrl: './my-lisitngs.component.html',
    styleUrls: ['./my-lisitngs.component.scss'],
})
export class MyListingsComponent implements OnInit {

    private offset = 0;
    private user: any;
    public restaurants: RestaurantData[] = [];
    public loaded: boolean;
    mode: string;

    constructor(public router: Router,
                private restaurantsService: RestaurantsService,
                private imagesService: ImagesService,
                private userService: UsersService,
                private route: ActivatedRoute,
                public popoverController: PopoverController,
                public toastService: ToastService) {
        this.loaded = false;
        this.userService.onUserChanged().subscribe(user => {
            this.user = user;
        });

        this.route.params.subscribe(() => {
            this.loadListings();
        });
    }

    ngOnInit() {
    }

    public loadListings(event?) {

        const userId = this.userService.getUser().id;

        if (!userId) {
            this.loaded = true;
            return;
        }

        this.restaurantsService.getUsersRestaurants(userId, this.offset).subscribe((result: RestaurantData[]) => {
            this.offset += result.length;
            this.restaurants = [...this.restaurants, ...result];

            if (event?.target) {
                event.target.complete();
            }

            if (event?.target && result.length < 20) {
                event.target.disabled = true;
            }

            const restaurantIds = this.restaurants.map(r => r._id).filter(r => !!r);

            this.imagesService.getThumbnails(restaurantIds).subscribe(images => {
                this.restaurants.forEach(r => r.thumbnail = images.get(r._id));
                this.loaded = true;
            }, () => {
                this.loaded = true;
            }, () => {
                this.loaded = true;
            });
        });
    }

    openRestaurant(restaurant) {
        this.router.navigate(['restaurant', !!restaurant.id ? restaurant.id : restaurant._id]);
    }

    activateRestaurant(restaurant) {
        this.router.navigate(['restaurant', !!restaurant.id ? restaurant.id : restaurant._id, "activate"]);
    }

    async presentPopover(ev, listing: RestaurantData) {
        const popover = await this.popoverController.create({
            component: ListingOptionsComponent,
            translucent: true,
            event: ev,
            componentProps: {active: listing.active}
        });
        await popover.present();

        return popover.onDidDismiss().then(
            (data: any) => {
                if (data && data.data && data.data.data === "deactivate") {
                    this.deactivateListing(listing);
                }

                if (data && data.data && data.data.data === "delete") {
                    this.deleteListing(listing);
                }

                if (data && data.data && data.data.data === "activate") {
                    this.activateRestaurant(listing);
                }
            });
    }

    private deleteListing(listing: RestaurantData) {
        this.restaurantsService.delete(listing._id).subscribe(async res => {
            !res ? await this.toastService.errorToast() : await this.toastService.infoToast();
            this.restaurants = this.restaurants.filter(l => l.id !== listing.id);

        }, async () => {
            await this.toastService.errorToast();
        });
    }

    private deactivateListing(listing: RestaurantData) {
        this.restaurantsService.deactivate(listing._id).subscribe(async res => {
            !res ? await this.toastService.errorToast() : await this.toastService.infoToast();
            listing.active = false;
        }, async (error) => {
            await this.toastService.errorToast();
        });
    }
}
