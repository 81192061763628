import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../services/users.service';
import {LoginData} from '../../models/login-data';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform, PopoverController} from "@ionic/angular";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {ToastService} from "../../services/toast.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup;
    public showPW = false;
    public claimRestaurant = "";
    public registerLink = "";

    constructor(public platform: Platform,
                private usersService: UsersService,
                private formBuilder: FormBuilder,
                private toastService: ToastService,
                private route: ActivatedRoute,
                public router: Router,
                public popoverController: PopoverController) {
        this.route.params.subscribe(params => {
            this.claimRestaurant = params?.claim;
            this.registerLink = !this.claimRestaurant ? '/registrierung' : `/registrierung/restaurant/${this.claimRestaurant}`;
        });
    }


    get email() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'), Validators.required])],
            password: ['', Validators.compose([
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
        });
    }

    loginUser() {

        if (!this.loginForm.valid) {
            return;
        }

        const user = new LoginData();
        user.email = this.loginForm.value.email;
        user.password = this.loginForm.value.password;


        this.usersService.loginUser(user).subscribe(async res => {
            this.usersService.setUser(res);
            this.usersService.sendUser(res);
            await this.toastService.infoToast("Erfolgreich angemeldet");

            if (this.claimRestaurant) {
                await this.router.navigate([`/liste/claim/${this.claimRestaurant}`]);
                return;
            }

            await this.router.navigate(['/']);
        }, async error => {
            await this.toastService.errorToast(error && error.error ? error.error : "");
        });
    }

    async presentPasswordPopover() {
        const popover = await this.popoverController.create({
            component: ForgotPasswordComponent,
            componentProps: {
                email: this.email.value
            }
        });
        // @ts-ignore
        await popover.present();

        return popover.onDidDismiss().then(
            () => {

            });
    }
}
