import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RestaurantsService} from './services/restaurants.service';
import {SearchService} from './services/search.service';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './user/login/login.component';
import {ProfileComponent} from './user/profile/profile.component';
import {RestaurantComponent} from './restaurant/restaurant.component';
import {RegisterComponent} from './user/register/register.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoogleMapsModule} from '@angular/google-maps';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {NgxFileDropModule} from 'ngx-file-drop';
import {NgSelectModule} from '@ng-select/ng-select';
import {TokenInterceptor} from './services/token.interceptor';
import {MyListingsComponent} from './restaurant/lisitngs/my-listings.component';
import {ListingOptionsComponent} from './restaurant/listing-options/listing-options.component';
import {LocationOptionsComponent} from './home/location-options/location-options.component';
import {FilterOptionsComponent} from './restaurant/filter-options/filter-options.component';
import {ForgotPasswordComponent} from './user/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './user/reset-password/reset-password.component';
import {SharedModule} from "./shared/shared.module";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {FooterComponent} from "./footer/footer.component";
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {NgxHotjarModule} from "ngx-hotjar";
import {MembershipComponent} from "./membership/membership.component";
import {AddressFormComponent} from "./common/address-form/address-form.component";
import {NgxPayPalModule} from "ngx-paypal";
import {CategoriesComponent} from "./restaurant/categories/categories.component";
import {ClaimRestaurantComponent} from "./user/claim-restaurant/claim-restaurant.component";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ProfileComponent,
        RestaurantComponent,
        RegisterComponent,
        MyListingsComponent,
        DashboardComponent,
        ListingOptionsComponent,
        LocationOptionsComponent,
        FilterOptionsComponent,
        ForgotPasswordComponent,
        ClaimRestaurantComponent,
        ResetPasswordComponent,
        FooterComponent,
        MembershipComponent,
        AddressFormComponent,
        CategoriesComponent
    ],
    entryComponents: [],
    imports: [
        GooglePlaceModule,
        BrowserModule,
        HttpClientModule,
        NgxHotjarModule.forRoot('1745530'),
        NgxGoogleAnalyticsModule.forRoot('UA-72513385-3'),
        IonicModule.forRoot(),
        IonicModule.forRoot({
            mode: 'md',
            scrollAssist: false,
            scrollPadding: false
        }),
        SharedModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        NgxFileDropModule,
        NgSelectModule,
        NgxPayPalModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        RestaurantsService,
        SearchService,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {

}
