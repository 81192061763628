import {RestaurantImageData} from "../models/restaurant-image-data";
import {Observable} from "rxjs";
import {RestaurantData} from "../models/locations/restaurant-data";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PlatformService} from "./platform.service";

@Injectable({
    providedIn: 'root'
})
export class ImagesService {

    URL = '';
    httpOptions = {responseType: 'text'};

    constructor(private http: HttpClient,
                private platformService: PlatformService) {
        this.URL = this.platformService.getPlatFormPath("images");
    }

    public addInstagramImages(restaurantId: string, link: string): Observable<RestaurantData> {
        return this.http.get(`${this.URL}/instagram/${restaurantId}`).pipe(map(res => new RestaurantData(res)));
    }

    public uploadImages(images: RestaurantImageData): Observable<RestaurantData> {
        return this.http.post(`${this.URL}`, images.toJSON())
            .pipe(
                map(res => new RestaurantData(res))
            );
    }

    public getImagesByRestaurant(link: string): Observable<RestaurantImageData> {
        return this.http.get(`${this.URL}/by-link/${link}`)
            .pipe(
                map(res => new RestaurantImageData(res))
            );
    }

    public getRestaurantImagesById(id: string): Observable<RestaurantImageData> {
        return this.http.get(`${this.URL}/by-id/${id}`)
            .pipe(
                map(res => new RestaurantImageData(res))
            );
    }

    public chooseProfilePicture(restaurant: string, image: string): Observable<RestaurantImageData> {
        return this.http.get(`${this.URL}/profile/${restaurant}/${image}`)
            .pipe(
                map(res => new RestaurantImageData(res))
            );
    }

    public getThumbnails(restaurants: string[]): Observable<Map<string, string>> {
        return this.http.post(`${this.URL}/thumbnails`, restaurants)
            .pipe(
                map(res => new Map(JSON.parse(JSON.stringify(res))))
            );
    }

    public delete(restaurantId: string, imageId: string): Observable<boolean> {
        return this.http.delete(`${this.URL}/delete/${restaurantId}/${imageId}`)
            .pipe(
                map(res => !!res)
            );
    }
}
