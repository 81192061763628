import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StepperData} from "../../models/stepper-data";

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {

    @Input() steps: string[] = [];
    @Output() checkNextStep: EventEmitter<void> = new EventEmitter<void>();
    public activeStep = 0;

    constructor() {
    }

    ngOnInit() {
    }

    nextStep(invalid?: boolean) {

        if (invalid) {
            return;
        }

        if (this.activeStep + 1 >= this.steps.length) {
            this.activeStep = 0;
        }

        this.activeStep += 1;
    }

    selectStep(index: number) {

        if (index === this.activeStep || index > this.activeStep + 2) {
            return;
        }

        if (index <= this.activeStep) {
            this.activeStep = index;
            return;
        }

        if (index === this.activeStep + 1) {
            this.checkNextStep.emit();
        }
    }
}
