import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UsersService} from "../../services/users.service";
import {RegisterData} from "../../models/register-data";
import {UserData} from "../../models/user-data";
import {ResetPasswordData} from "../../models/reset-password-data";
import {ToastService} from "../../services/toast.service";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {AlertController, PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    showCurrent: boolean;
    showNew: boolean;
    showRepeat: boolean;
    resetForm: FormGroup;
    isOwner = false;
    profileForm: FormGroup;
    private user: RegisterData;
    public profileError: string;
    public resetError: string;

    private storedUser: UserData;
    loaded: boolean;


    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private userService: UsersService,
                private toastService: ToastService,
                public router: Router,
                public popoverController: PopoverController,
                public alertController: AlertController) {
        this.loaded = false;
        this.route.params.subscribe(params => {
            this.loaded = false;
            this.storedUser = this.userService.getUser();
            this.userService.findUser(this.storedUser.id).subscribe(res => {
                this.user = res;
                this.isOwner = this.user.is_owner;
                this.profileForm.setValue({
                    email: this.user.email,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    updates_enabled: this.user.updates_enabled,
                });
            });
            this.loaded = true;
        });
    }


    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            newPw: ['', Validators.compose([
                Validators.maxLength(30),
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
            currentPw: ['', Validators.compose([
                Validators.maxLength(30),
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
            repeatPw: ['', Validators.compose([
                Validators.maxLength(30),
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
        });

        this.profileForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.maxLength(30),
                Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'),
                Validators.required])],
            first_name: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
            last_name: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
            updates_enabled: [],
        });

    }

    // PASSWORT
    get newPw() {
        return this.resetForm.get('newPw');
    }

    get currentPw() {
        return this.resetForm.get('currentPw');
    }

    get repeatPw() {
        return this.resetForm.get('repeatPw');
    }

    // PROFILE
    get email() {
        return this.profileForm.get('email');
    }

    get first_name() {
        return this.profileForm.get('first_name');
    }

    get last_name() {
        return this.profileForm.get('last_name');
    }

    get updates_enabled() {
        return this.profileForm.get('last_name');
    }

    setUpdatesEnabled(value) {
        this.profileForm.setValue(value);
    }

    updateUser() {

        if (!this.profileForm.valid) {
            return;
        }

        const user = new RegisterData();
        user.is_owner = this.isOwner;
        user.email = this.profileForm.value.email;
        user.password = this.user.password;
        user.password_repeated = this.user.password_repeated;
        user.first_name = this.profileForm.value.first_name;
        user.last_name = this.profileForm.value.last_name;
        user.updates_enabled = this.profileForm.value.updates_enabled;

        this.userService.updateUser(user, this.storedUser.id).subscribe(async res => {
            this.userService.setUser(res);
            this.userService.sendUser(res);
            this.profileError = '';
            await this.toastService.infoToast();
        }, async error => {
            this.profileError = !!error && !!error.error ? error.error : '';
            await this.toastService.errorToast();
        });
    }

    resetPassword() {

        if (!this.resetForm.valid) {
            return;
        }

        this.userService.resetPassword(new ResetPasswordData({
            id: this.storedUser.id,
            password: this.currentPw.value,
            newPassword: this.newPw.value,
        })).subscribe(async res => {
            this.resetError = '';
            await this.toastService.successToast('Passwort erfolgreich geändert');
            this.resetForm.reset();
        }, async error => {
            this.resetError = error.error;
            await this.toastService.errorToast();
        });
    }

    async presentPasswordPopover() {
        const popover = await this.popoverController.create({
            component: ForgotPasswordComponent,
            componentProps: {}
        });
        // @ts-ignore
        await popover.present();

        return popover.onDidDismiss().then(
            () => {
            });
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            cssClass: '',
            header: 'Account löschen?',
            message: 'Möchten du Deinen Account <strong>unwiderruflich</strong> löschen?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        return;
                    }
                }, {
                    text: 'Ja Löschen',
                    handler: () => {
                        this.userService.deleteUser().subscribe(async () => {
                            await this.toastService.infoToast('Account erfolgreich gelöscht.');
                            this.userService.removeUser();
                            this.router.navigate(['/']);
                        }, async error => {
                            this.resetError = error.error;
                            await this.toastService.errorToast('Account konnte nicht gelöscht werden.');
                        });
                    }
                }
            ]
        });

        await alert.present();
    }

}
