export class RestaurantImageData {

    private _id: string;
    private _images: string[];

    toJSON() {
        return {
            id: this.id,
            images: this.images,
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get images(): string[] {
        return this._images;
    }

    set images(value: string[]) {
        this._images = value;
    }
}
