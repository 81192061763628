import {Component, Input, OnInit} from '@angular/core';
import {RestaurantData} from "../../models/locations/restaurant-data";
import {Router} from "@angular/router";

@Component({
    selector: 'app-restaurant-list',
    templateUrl: './restaurant-list.component.html',
    styleUrls: ['./restaurant-list.component.scss'],
})
export class RestaurantListComponent implements OnInit {
    @Input() restaurants: RestaurantData[] = [];

    constructor(public router: Router) {
    }

    ngOnInit() {
    }

    async openRestaurant(restaurant: RestaurantData) {
        await this.router.navigate(['liste', restaurant.link_name]);
    }

}
