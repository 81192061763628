import {Component, OnInit} from '@angular/core';
import {NavParams, PopoverController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../services/users.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    resetForm: FormGroup;
    mailSent: boolean;

    constructor(public navParams: NavParams, private popover: PopoverController,
                private formBuilder: FormBuilder,
                private usersService: UsersService) {
        const emailInput = navParams?.data?.email;

        this.resetForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.maxLength(30),
                Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'),
                Validators.required])]
        });

        if (!!emailInput) {
            this.resetForm.controls['email'].setValue(emailInput);
            this.resetForm.controls['email'].setValidators(Validators.compose([Validators.maxLength(30),
                Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'),
                Validators.required]));
        }

    }

    get email() {
        return this.resetForm.get('email');
    }

    set email(emailInput) {
    }

    ngOnInit() {

    }

    reset() {

        if (!this.email || !this.email.value || this.email.invalid) {
            return;
        }
        this.mailSent = true;

        this.usersService.resetMail(this.email.value).subscribe(res => {
        });
    }

    tryAgain() {
        this.popover.dismiss({data: "activate"});
    }
}
