import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UsersService} from "../services/users.service";
import {RestaurantsService} from "../services/restaurants.service";
import {RestaurantData} from "../models/locations/restaurant-data";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    constructor(private route: ActivatedRoute, private userService: UsersService, private restaurantsService: RestaurantsService) {
        this.route.params.subscribe(params => {
            this.loadListings();
        });
    }

    public userRestaurants: RestaurantData[] = [];
    public totalListings = 0;
    public totalViews = 0;
    public loaded: boolean;

    ngOnInit() {
    }

    public loadListings() {
        const userId = this.userService.getUser().id;

        if (!userId) {
            return;
        }

        this.restaurantsService.getUsersRestaurants(userId, -1).subscribe((restaurants: RestaurantData[]) => {
            this.userRestaurants = restaurants;
            this.totalViews = 0;
            this.totalListings = this.userRestaurants.filter(l => l.active).length;
            this.userRestaurants.forEach(l => {
                this.totalViews = this.totalViews + l.views;
            });
            this.loaded = true;
        }, () => this.loaded = true);
    }
}
