import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ShippingData} from "../models/locations/shipping-data";
import {PlatformService} from "./platform.service";

@Injectable({
    providedIn: 'root'
})
export class MembershipService {

    URL = '';
    httpOptions = {responseType: 'text'};

    constructor(private http: HttpClient, private platformService: PlatformService) {
        this.URL = this.platformService.getPlatFormPath("membership");
    }

    public saveSubscription(shippingData: ShippingData): Observable<void> {
        return this.http.post(`${this.URL}/add`, shippingData.toJSON())
            .pipe(
                map(res => {
                    return;
                })
            );
    }

    public cancel(): Observable<boolean> {
        return this.http.get(`${this.URL}/cancel`)
            .pipe(
                map(res => !!res)
            );
    }

    public getActive(): Observable<boolean> {
        return this.http.get(`${this.URL}/active`)
            .pipe(
                map(res => !!res)
            );
    }
}
