export class SearchTagData {

    private _id: string;
    private _name: string;
    private _group: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            group: this.group
        };
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get group(): string {
        return this._group;
    }

    set group(value: string) {
        this._group = value;
    }
}
