import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    httpOptions = {responseType: 'text'};

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    }

    getItem(key: string): any {
        return this.storage.get(key);
    }

    setItem(key: string, data) {
        this.storage.set(key, data);
    }

    removeIem(key: string) {
        this.storage.remove(key);
    }
}
