import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../services/users.service";
import {ClaimRestaurantData} from "../../models/claim-restaurant-data";
import {NavParams, PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-claim-restaurant',
    templateUrl: './claim-restaurant.component.html',
    styleUrls: ['./claim-restaurant.component.scss'],
})
export class ClaimRestaurantComponent implements OnInit {
    claimForm: FormGroup;

    private readonly restaurant: string;

    constructor(private popover: PopoverController,
                private formBuilder: FormBuilder,
                public navParams: NavParams,
                private userService: UsersService) {
        this.restaurant = this.navParams?.data?.restaurant;

        this.claimForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.maxLength(30),
                Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'),
                Validators.required])],
            name: ['', Validators.compose([Validators.required])]
        });

        this.userService.findUser(this.userService.getUser().id).subscribe(user => {

            if (!!user) {
                this.claimForm.controls['name'].setValue(`${user.first_name} ${user.last_name}`);
                this.claimForm.controls['email'].setValue(user.email);
            }
        });
    }

    ngOnInit() {
    }

    claim() {
        const claimRestaurantData = new ClaimRestaurantData({
            restaurant_id: this.restaurant
        });

        claimRestaurantData.email = this.email;
        claimRestaurantData.name = this.name;

        this.userService.claimRestaurant(claimRestaurantData).subscribe();
        this.popover.dismiss();
    }

    get email() {
        return this.claimForm.get('email')?.value || "";
    }

    set email(emailInput) {
        this.claimForm.controls['email'].setValue(emailInput.value);
    }

    get name() {
        return this.claimForm.get('name')?.value || "";
    }

    set name(emailInput) {
        this.claimForm.controls['name'].setValue(emailInput.value);
    }
}
