import {Injectable} from '@angular/core';
import {ToastController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastController: ToastController) {
    }

    async errorToast(errorMessage?: string) {
        errorMessage = !errorMessage ? "Achtung, etwas ging schief." : errorMessage;

        return (await this.toastController.create({
            message: errorMessage,
            duration: 2000,
            animated: true,
            color: 'danger',
            position: 'bottom',
            buttons: [
                {
                    text: 'Okay',
                    role: 'X',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        })).present();
    }

    async successToast(successMessage: string) {
        return (await this.toastController.create({
            message: successMessage,
            duration: 2000000000,
            animated: true,
            position: 'bottom',
            color: 'success',
            cssClass: 'toastAboveTab',
            buttons: [
                {
                    text: 'Okay',
                    role: 'X',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        })).present();
    }

    async infoToast(infoMessage?: string) {
        infoMessage = !infoMessage ? "Änderungen gespeichert." : infoMessage;
        return (await this.toastController.create({
            message: infoMessage,
            duration: 2000,
            animated: true,
            color: 'secondary',
            position: 'bottom',
            buttons: [
                {
                    text: 'Okay',
                    role: 'X',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        })).present();
    }
}
