import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {UsersService} from './users.service';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {ToastService} from "./toast.service";
import {Platform} from "@ionic/angular";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public router: Router,
                public toastService: ToastService,
                public userService: UsersService,
                public usersService: UsersService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.usersService.getToken();

        if (!!token) {
            request = request.clone({
                setHeaders: {
                    authorization: token
                }
            });
        }

        return next.handle(request).pipe(tap(() => {
            },
            async (err: any) => {
                if (err instanceof HttpErrorResponse) {

                    if (err.status !== 401) {
                        return;
                    }

                    await this.toastService.errorToast('Ups, Session abgelaufen! Bitee melde dich neu an.');
                    this.userService.removeUser();
                    await this.router.navigate(['login']);
                }
            }));
    }
}
