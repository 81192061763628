import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
    selectedCategories = [];

    @Output() categoryChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Input() set categories(categories: string[]) {
        categories.forEach(category => {
            if (!this.allCategories.includes(category)) {
                this.allCategories.push(category);
            }
        });

        if (!this.selectedCategories?.length) {
            this.selectedCategories = [...categories];
        }
    }

    public allCategories = ["Afghanisch", "Amerikanisch", "Arabisch", "Asiatisch", "Balti-Curry", "Bar", "Barbecue", "Café",
        "Chinesisch", "Deutsch", "Europäisch", "Fast Food", "Französisch", "Fusion", "Gasthausbrauerei", "Gastropub",
        "Griechisch", "Grillspezialitäten", "Indisch", "International",
        "Italienisch", "Japanisch", "Japanische Fusion-Küche", "Katalanisch",
        "Kroatisch", "Lebensmittel mit medizinischen Eigenschaften", "Libanesisch",
        "Marokkanisch", "Mediterran", "Meeresfrüchte", "Mexikanisch", "Mittelamerikanisch", "Mitteleuropäisch",
        "Mongolisch", "Orientalisch", "Pakistanisch", "Pizza", "Pub", "Rumänisch", "Russisch", "Sizilianisch", "Spanisch",
        "Speiselokal", "Steakhaus", "Street Food", "Suppen", "Sushi", "Südamerikanisch", "Süditalienisch",
        "Thailändisch", "Türkisch", "Vietnamesisch", "Weinbars & Weinstuben", "Zeitgenössisch"
    ];
    alertOptions = {
        cssClass: 'select-categories'
    };

    constructor() {
    }

    ngOnInit() {
    }

    changeCategories($event) {
        this.selectedCategories = [...$event];
        this.categoryChanged.emit(this.selectedCategories);
    }
}
