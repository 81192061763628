import {AutocompleteData} from "../models/autocomplete-data";
import {Observable} from "rxjs";
import {SearchTagData} from "../models/search-tag-data";
import {map} from "rxjs/operators";
import {SearchData} from "../models/search-data";
import {RestaurantData} from "../models/locations/restaurant-data";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PlatformService} from "./platform.service";

@Injectable()
export class SearchService {

    URL = '';
    httpOptions = {responseType: 'text'};

    constructor(private http: HttpClient, private platformService: PlatformService) {
       this.URL = this.platformService.getPlatFormPath("search");
    }

    public getSearchTags(autocompleteData: AutocompleteData): Observable<SearchTagData[]> {
        return this.http.post(`${this.URL}/search-data`, autocompleteData.toJSON())
            .pipe(
                map((res: SearchTagData[]) => {
                    return res.map(item => new SearchTagData(item));
                }));
    }

    public searchRestaurants(searchData: SearchData): Observable<RestaurantData[]> {
        return this.http.post(`${this.URL}/`, searchData)
            .pipe(
                map((res: RestaurantData[]) => {
                    return res.map(item => new RestaurantData(item));
                }));
    }

    public getHighlighted(): Observable<RestaurantData[]> {
        return this.http.get(`${this.URL}/highlighted`)
            .pipe(
                map((res: RestaurantData[]) => {
                    return res.map(item => new RestaurantData(item));
                }));
    }
}
