export class PaymentProviderData {

    private _id: string;
    private _title: string;
    private _description: number;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            description: this.description
        };
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): number {
        return this._description;
    }

    set description(value: number) {
        this._description = value;
    }

}
