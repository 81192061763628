export class Location {

    private _street_name = '';
    private _street_number: string;
    private _code: number;
    private _city = '';

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get street_name(): string {
        return this._street_name;
    }

    set street_name(value: string) {
        this._street_name = !value ? "" : value;
    }

    get street_number(): string {
        return this._street_number;
    }

    set street_number(value: string) {
        this._street_number = value;
    }

    get code(): number {
        return this._code;
    }

    set code(value: number) {
        this._code = !value ? 0 : value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = !value ? "" : value;
    }

    get formatted_address(): string {
        return `${this.street_name} ${this.street_number}, ${this.code}  ${this.city}`;
    }

    get csv_address(): string {
        return `${this.street_name},${this.street_number},${this.code},${this.city}`;
    }

    hasAddress() {
        return !!this.street_name && !!this.city && !!this.code && !!this.street_number;
    }

    setLocation(location: Location) {
        this.street_name = location.street_name;
        this.street_number = location.street_number;
        this.city = location.city;
        this.code = location.code;
    }
}
