import {Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    httpOptions = {responseType: 'text'};

    constructor(public platform: Platform) {
    }

    public getPlatFormPath(param: string): string {
        const prefix = "api";
        // TODO : FÜR APP RELEASE
        // const prefix = !this.platform.is("desktop") || this.platform.is("mobileweb") ? "https://18.185.46.149/api" : "/api";
        console.log(`${prefix}/${param}`);
        return `${prefix}/${param}`;
    }
}
