import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-opentable-button',
    templateUrl: './opentable-button.component.html',
    styleUrls: ['./opentable-button.component.scss'],
})
export class OpentableButtonComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    close() {
        console.log("close");
    }
}
