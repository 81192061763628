import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ResetPasswordData} from "../../models/reset-password-data";
import {ActivatedRoute, Router} from "@angular/router";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {UsersService} from "../../services/users.service";
import {ToastService} from "../../services/toast.service";
import {PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

    showNew: boolean;
    showRepeat: boolean;
    resetForm: FormGroup;
    public resetError: string;
    private token: string;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private userService: UsersService,
                public router: Router,
                private toastService: ToastService,
                private usersService: UsersService,
                public popoverController: PopoverController) {

        this.route.params.subscribe(params => {

            if (!params.token) {
                this.router.navigate(['/']);
            }
            this.token = params.token;
        });
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            newPw: ['', Validators.compose([
                Validators.maxLength(30),
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
            repeatPw: ['', Validators.compose([
                Validators.maxLength(30),
                Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'),
                Validators.required])],
        });
    }

    get newPw() {
        return this.resetForm.get('newPw');
    }

    get repeatPw() {
        return this.resetForm.get('repeatPw');
    }

    resetPassword() {
        this.userService.resetPasswordByMail(new ResetPasswordData({
            id: this.token,
            newPassword: this.newPw.value,
        })).subscribe(async res => {
            this.resetError = '';

            await this.toastService.successToast('Passwort erfolgreich geändert');
            this.resetForm.reset();
            this.resetError = '';
            this.usersService.setUser(res);
            this.usersService.sendUser(res);
            await this.router.navigate(['/']);
        }, async error => {
            this.resetError = error.error ? error.error : 'Zurücksetzen fehlgeschlagen';
            await this.toastService.errorToast();

        });
    }

    async presentPasswordPopover() {
        const popover = await this.popoverController.create({
            component: ForgotPasswordComponent,
            componentProps: {}
        });
        // @ts-ignore
        await popover.present();

        return popover.onDidDismiss().then(
            () => {

            });
    }
}
