import {Component, OnInit} from '@angular/core';
import {NavParams} from "@ionic/angular";
import {RestaurantsService} from "../../services/restaurants.service";

@Component({
    selector: 'app-filter-options',
    templateUrl: './filter-options.component.html',
    styleUrls: ['./filter-options.component.scss'],
})
export class FilterOptionsComponent implements OnInit {
    selections: string[];
    types: string[];

    constructor(public navParams: NavParams, public restaurantsService: RestaurantsService) {
        this.types = this.navParams.data.type;
        this.selections = this.navParams.data.selections;
    }

    sendToParent(val) {
        this.selections = !this.selections ? [] : this.selections;
        if (val.detail.checked) {
            this.selections.push(val.detail.value);
        } else {
            this.selections = this.selections.filter(s => s !== val.detail.value);
        }

        this.restaurantsService.filterBs.next(this.selections);
    }

    ngOnInit() {
    }

}
