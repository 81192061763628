import {Component, OnInit} from '@angular/core';
import {RegisterData} from '../../models/register-data';
import {UsersService} from '../../services/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from "../../services/toast.service";
import {Platform} from "@ionic/angular";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    isOwner = false;
    claimRestaurant = "";
    registerForm: FormGroup;
    showPW = false;
    showRepeatPW = false;
    error = '';
    loginLink = '';

    constructor(private route: ActivatedRoute,
                private usersService: UsersService,
                private formBuilder: FormBuilder,
                private toastService: ToastService,
                public router: Router) {
        this.route.params.subscribe(params => {
            this.isOwner = !!params?.isOwner;
            this.claimRestaurant = params?.claim;
            this.loginLink = !this.claimRestaurant ? '/login' : `/login/restaurant/${this.claimRestaurant}`;
        });
    }

    get email() {
        return this.registerForm.get('email');
    }

    get password() {
        return this.registerForm.get('password');
    }

    get password_repeated() {
        return this.registerForm.get('password_repeated');
    }

    get first_name() {
        return this.registerForm.get('first_name');
    }

    get last_name() {
        return this.registerForm.get('last_name');
    }

    get updates_enabled() {
        return this.registerForm.get('last_name');
    }

    setUpdatesEnabled(value) {
        this.registerForm.setValue(value);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+'), Validators.required])],
            password: ['', Validators.compose([Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}$'), Validators.required])],
            password_repeated: ['', Validators.compose([Validators.required])],
            first_name: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
            last_name: ['', Validators.compose([Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*'), Validators.required])],
            updates_enabled: [],
        });
    }

    registerUser() {

        if (!this.registerForm.valid) {
            return;
        }

        const user = new RegisterData();
        user.is_owner = this.isOwner;
        user.email = this.registerForm.value.email;
        user.password = this.registerForm.value.password;
        user.password_repeated = this.registerForm.value.password_repeated;
        user.first_name = this.registerForm.value.first_name;
        user.last_name = this.registerForm.value.last_name;
        user.updates_enabled = this.registerForm.value.updates_enabled;

        this.usersService.registerUser(user).subscribe(async res => {
            this.error = '';
            this.usersService.setUser(res);
            this.usersService.sendUser(res);
            await this.toastService.infoToast("Profil erfolgreich erstellt");

            if (this.claimRestaurant) {
                await this.router.navigate([`/liste/claim/${this.claimRestaurant}`]);
                return;
            }

            await this.router.navigate(['/']);
        }, async error => {
            await this.toastService.errorToast(error && error.error ? error.error : "");
        });
    }
}
