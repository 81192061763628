export class SubscriptionProductData {

    private _id: number;
    private _name: string;
    private _price: number;
    private _features = [];

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            price: this.price,
            features: this.features
        };
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get price(): number {
        return this._price;
    }

    set price(value: number) {
        this._price = value;
    }

    get features(): any[] {
        return this._features;
    }

    set features(value: any[]) {
        this._features = value;
    }
}
