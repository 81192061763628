import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {RestaurantsService} from "../../services/restaurants.service";
import {LocationData} from "../../models/location-data";
import {NavParams, PopoverController} from "@ionic/angular";
import {Plugins} from "@capacitor/core";
import {LocalStorageService} from "../../services/local-storage.service";

const {Geolocation} = Plugins;

@Component({
    selector: 'app-location-options',
    templateUrl: './location-options.component.html',
    styleUrls: ['./location-options.component.scss'],
})
export class LocationOptionsComponent implements OnInit, AfterViewInit {
    public locationData = new LocationData();
    public address = "";
    public distance = 20;
    public searchButton = false;

    constructor(private popover: PopoverController,
                public navParams: NavParams,
                public restaurantsService: RestaurantsService,
                private localStorageService: LocalStorageService,
                public element: ElementRef<HTMLElement>) {

        this.locationData = !!navParams.data.locationData ? new LocationData(navParams.data.locationData) : new LocationData();
        this.distance = !this.locationData || !this.locationData.distance ? this.distance : this.locationData.distance;
        this.address = !this.locationData.address ? "" : this.locationData.address;
        this.searchButton = !!navParams.data.searchButton;

        if (this.locationData && this.locationData.longitude && this.locationData.latitude && !this.locationData.address) {
            this.getAddress();
        }
    }

    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    options: any;

    ngOnInit() {
        this.options = {
            types: ['address'],
            componentRestrictions: {country: 'de'},
            fields: ["formatted_address"]
        };
    }


    ngAfterViewInit(): void {
     /*   setTimeout(() => {
            document.getElementById("input-c").focus();
            Keyboard.show().then(r => console.log(r));
        }, 500); */
    }

    changeLocation(value) {
        this.locationData = !!this.locationData ? this.locationData : new LocationData();
        this.locationData.distance = value.detail.value;
        this.restaurantsService.locationBs.next(this.locationData);
    }

    changeAddress(add: Address) {
        this.restaurantsService.getLocation(add.formatted_address).subscribe(res => {
            this.locationData = !!this.locationData ? this.locationData : new LocationData();
            this.distance = this.locationData.distance;
            this.locationData = !res ? new LocationData() : res;
            this.locationData.distance = this.distance;
            this.locationData.address = add.formatted_address;
            this.localStorageService.setItem("location", this.locationData);
            this.address = this.locationData.address;
            this.restaurantsService.locationBs.next(this.locationData);
        });
    }

    async getLocation() {

        if (!!this.locationData && !!this.locationData.address) {
            return;
        }

        const coordinates = await Geolocation.getCurrentPosition();
        this.locationData.longitude = coordinates.coords.longitude;
        this.locationData.latitude = coordinates.coords.latitude;

        this.getAddress();
    }

    private getAddress() {
        this.restaurantsService.getAddress(this.locationData).subscribe(res => {
            this.locationData.address = res;
            this.address = res;
        });
    }

    search() {
        this.popover.dismiss({search: true});
    }
}
