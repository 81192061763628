export class RegisterData {

    private _is_owner: boolean;
    private _email: string;
    private _password: string;
    private _password_repeated: string;
    private _first_name: string;
    private _last_name: string;
    private _updates_enabled: boolean;

    toJSON() {
        return {
            is_owner: this.is_owner,
            email: this.email,
            password: this.password,
            password_repeated: this.password_repeated,
            first_name: this.first_name,
            last_name: this.last_name,
            updates_enabled: this.updates_enabled
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get is_owner(): boolean {
        return this._is_owner;
    }

    set is_owner(value: boolean) {
        this._is_owner = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get password_repeated(): string {
        return this._password_repeated;
    }

    set password_repeated(value: string) {
        this._password_repeated = value;
    }

    get first_name(): string {
        return this._first_name;
    }

    set first_name(value: string) {
        this._first_name = value;
    }

    get last_name(): string {
        return this._last_name;
    }

    set last_name(value: string) {
        this._last_name = value;
    }

    get updates_enabled(): boolean {
        return this._updates_enabled;
    }

    set updates_enabled(value: boolean) {
        this._updates_enabled = value;
    }

}
