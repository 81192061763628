import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {AddDishPopoverComponent} from "../restaurant/add-dish-popover/add-dish-popover.component";
import {DishData} from "../models/dish-data";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    @Output() onAddDish: EventEmitter<DishData> = new EventEmitter<DishData>();
    @Output() onRemoveDish: EventEmitter<string> = new EventEmitter<string>();

    @Input() menu: any = [];
    @Input() hidePrices = false;
    @Input() showEdit = false;
    @Input() dishCategories = [];

    hoverItem: string;

    constructor(public popoverController: PopoverController) {
    }

    ngOnInit() {
    }

    addDish() {
    }

    async openEditPopover(item: DishData) {
        const popover = await this.popoverController.create({
            component: AddDishPopoverComponent,
            cssClass: "popover-medium",
            componentProps: {dishCategories: this.dishCategories, dish: item, skipPrice: this.hidePrices}
        });
        await popover.present();

        return popover.onDidDismiss().then(
            (response) => {
                const removeData = response?.data?.deleteItem;

                if (removeData) {
                    this.onRemoveDish.emit(removeData);
                }

                this.onAddDish.emit(response?.data?.dish);
            });
    }
}
