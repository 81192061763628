export class LocationData {

    private _longitude: number;
    private _latitude: number;
    private _distance = 20;
    private _address: string;

    toJSON() {
        return {
            longitude: this.longitude,
            latitude: this.latitude,
            distance: this.distance,
            address: this.address
        };
    }

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get longitude(): number {
        return this._longitude;
    }

    set longitude(value: number) {
        this._longitude = value;
    }

    get latitude(): number {
        return this._latitude;
    }

    set latitude(value: number) {
        this._latitude = value;
    }

    get distance(): number {
        return this._distance;
    }

    set distance(value: number) {
        this._distance = value;
    }

    get address(): string {
        return this._address;
    }

    set address(value: string) {
        this._address = value;
    }
}
