export class DishData {

    private _title: string;
    private _description: string;
    private _price: string;
    private _category: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    toJSON() {
        return {
            title: this.title,
            description: this.description,
            price: this.price,
            category: this.category
        };
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get price(): string {
        return this._price;
    }

    set price(value: string) {
        this._price = value;
    }

    get category(): string {
        return this._category;
    }

    set category(value: string) {
        this._category = value;
    }

}
